<template>
  <LoadingFilter v-if="loading"/>
  <div v-else class="container filter__category-wrapper d-flex align-items-center justify-content-between">
    <div class="title__category">
      <h6>{{ catName }}</h6>
    </div>
    <div class="filter__category">
      <select id="filter" name="filter" class="form-select filter__select-category" v-model="filterCampaign" @change="handleSelectFilter">
        <option value="terbaru">
          Terbaru
        </option>
        <option value="terlama">
          Terlama
        </option>
        <option value="berakhir">
          Tanggal Berakhir
        </option>
      </select>
    </div>
  </div>
  <LoaderListCampaign v-if="loading" :count="5" />
  <div v-else class="container container__category">
    <router-link
      v-for="campaign in filterListCampaign.campaigns"
      :key="campaign.wakafId"
      class="list__category-wrapper"
      :to="`/${campaign.wakafUrl}`"
    >
      <div class="information__category">
        <div class="title__category-wrapper">
           <span class="title__category">
            {{ campaign.wakafName }}
          </span>
        </div>
        <div class="d-flex align-items-center" v-if="campaign.company.companyId !== null">
          <span class="company__name">{{ campaign.company.companyName }}</span>
          <svg class="h-2 w-2" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
            <path
              d="M6 0C2.691 0 0 2.691 0 6s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6z"
              fill="#0085FF"
            ></path>
            <path
              d="M8.85 4.442L5.531 7.654a.518.518 0 01-.722 0L3.15 6.048a.482.482 0 010-.699.522.522 0 01.723 0L5.17 6.606l2.958-2.863a.522.522 0 01.722 0c.2.194.2.506 0 .7z"
              fill="#FAFAFA"
            ></path>
          </svg>
        </div>
        <div class="progress progress__category">
          <div
            class="progress-bar"
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
            :style="{ width: `${campaign.wakafTargetPercent}%` }"
          />
        </div>
        <span class="title__donation">Terkumpul</span>
        <h5 class="value__donation">Rp. {{ $formatPrice(campaign.summary.totalTerhimpun) }}</h5>
        <button v-if="campaign.wakafStatus === 'published'" type="button" class="btn btn-danger btn-sm btn btn__end-category">
          <i class="icon-date-white" />
          <span class="value__end-date">
            <b>{{ $moment(campaign.wakafTargetDate).startOf('day').fromNow() }} </b>
          </span>
        </button>
        <button v-if="campaign.wakafStatus === 'fullfunded'" type="button" class="btn btn-secondary btn-sm btn btn__end-category">
          <i class="icon-date-white" />
          <span class="value__end-date">
            <b>Selesai</b>
          </span>
        </button>
      </div>
      <div class="tumbnail__category-gallery">
        <img :src="campaign.wakafImage" alt="Cover Image" class="category__cover-image" />
      </div>
    </router-link>

    <div class="pagination__category" v-if="listCampaign.length > 0">
      <span>
        <button
          type="button"
          class="btn btn-warning btn-sm pagtination__button pagination__prev"
          :class="{ disabled__pagination: pager.current_page === 1 }"
          @click="handlePreviousCampaign()"
        >
          Previous
        </button>
      </span>
      <span id="page_number" class="pagination__number" ref="page_number">
        <!-- <span  class="click__page-number" v-for="page in pager.per_page" :key="page" :style="{'background' : index == currentPage - 1 ? '#F28F1D' : 'none', 'color' : index == currentPage - 1 ? '#FFF' : '#000' }">{{pagination}}</span> -->
        <span
          v-for="(pagination, index) in pager.last_page"
          :key="index"
          class="click__page-number"
          :style="{
            background: index == pager.current_page - 1 ? '#F28F1D' : 'none',
            color: index == pager.current_page - 1 ? '#FFF' : '#000'
          }"
          @click="handlePaginationPage(pagination)"
        >
          {{ pagination }}
        </span>
      </span>
      <span>
        <button
          type="button"
          class="btn btn-warning btn-sm pagtination__button pagination__next"
          :class="{ disabled__pagination: pager.current_page === pager.last_page }"
          @click="handleNextCampaign()"
        >
          Next
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import { imageCdn } from '@/_helpers'
import LoadingFilter from '../../components/Loader/LoaderFilter.vue'
import LoaderListCampaign from '../Loader/LoaderListCampaign.vue'
export default {
  components: { LoaderListCampaign, LoadingFilter },
  // eslint-disable-next-line vue/require-prop-types
  props: ['catId','catName'],
  data() {
    return {
      pager: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        current_page: 1,
        total: 0,
        // eslint-disable-next-line @typescript-eslint/camelcase
        last_page: 0
      },
      paginations: [],
      recordsPerPage: 25,
      limit: 25,
      listCampaign: [],
      filterCampaign: 'terbaru',
      loading: true
    }
  },
  computed: {
    filterListCampaign() {
      let filterList = {}
      for (let i = 0; i < this.listCampaign.length; i++) {
        if (this.listCampaign[i].page == this.pager.current_page) {
          filterList = { campaigns: this.listCampaign[i].campaigns }
        }
      }

      return filterList
    }
  },

  mounted() {
    this.getCampaign(this.pager.current_page)
  },
  methods: {
    getCampaign(page) {
      const formData = new URLSearchParams()
      formData.append('cat_id',this.catId)
      formData.append('page',page)
      formData.append('limit',this.limit)
      
      if (this.filterCampaign === 'terbaru') {
        formData.append('order','published_at')
        formData.append('descending','0')
      }else if(this.filterCampaign === 'terlama'){
        formData.append('order','published_at')
        formData.append('ascending','1')
      }else if(this.filterCampaign === 'berakhir'){
        formData.append('order','target_date')
        formData.append('ascending','1')
      }

      fetch(
        `${process.env.VUE_APP_API_URL}campaigns?${formData.toString()}`,
        { method: 'GET' }
      )
        .then(response => response.json())
        .then(({ meta, data }) => {
          const categoryWrapper = document.querySelector('.container__wrapper')
          const arrListCampaign = []
          let objCurrentPage = {}
          data.forEach(value => {
            const totalTerhimpun = value.summary !== null ? value.summary.total_terhimpun : 0
            const percenWakaf = (totalTerhimpun / value.target) * 100
            // const valueTarget = value.target_date ? this.$moment(value.target_date)
            //   .startOf('day')
            //   .fromNow() : ''
            const objData = {
              wakafId: value.wakaf_id,
              wakafTargetDate: value.target_date ? value.target_date : '',
              wakafName: value.wakaf_name,
              wakafImage: imageCdn(value.wakaf_image_cover_url, 'campaign-list'),
              wakafTarget: value.target,
              // wakafTargetValue: valueTarget,
              wakafStatus: value.wakaf_status,
              wakafUrl: value.wakaf_url,
              summary: {
                wakafSummaryId: value.summary !== null ? value.summary.wakaf_summary_id : 0,
                totalTerhimpun:
                  value.summary !== null ? parseInt(value.summary.total_terhimpun) + 0 : 0,
                totalDonatur: value.summary !== null ? value.totalDonatur : 0
              },
              company: {
                companyId: value.company !== null ? value.company.company_id : null,
                companyName: value.company !== null ? value.company.company_name : null
              },
              wakafTargetPercent: parseInt(percenWakaf)
            }

            arrListCampaign.push(objData)

            objCurrentPage = {
              page: meta.current_page,
              campaigns: arrListCampaign
            }
          })

          this.listCampaign.push(objCurrentPage)
          this.loading = false

          // eslint-disable-next-line @typescript-eslint/camelcase
          this.pager.current_page = meta.current_page
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.pager.last_page = meta.last_page
        })
    },
    handleSelectFilter() {
      this.loading = true
      this.getCampaign(this.pager.current_page)
    },
    handleNextCampaign() {
      // eslint-disable-next-line @typescript-eslint/camelcase
      const currentPage = this.pager.current_page + 1
      this.loading = true
      this.getCampaign(currentPage)
    },
    handlePreviousCampaign() {
      // eslint-disable-next-line @typescript-eslint/camelcase
      const currentPage = this.pager.current_page - 1
      this.loading = true
      this.getCampaign(currentPage)
    },
    handlePaginationPage(page) {
      this.loading = true
      this.getCampaign(page)
    }
  }
}
</script>
