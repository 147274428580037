<template>
  <div class="video-container">
    <div class="lazy-loading-content image-container">
      <img :src="`http://i3.ytimg.com/vi/${videoUrl}/maxresdefault.jpg`" alt="thumbnail" class="video-thumbnail" />
      <div class="play-button" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    videoUrl: { type: String, default: null },
    embedUrl: { type: String, default: null }
  },
  mounted() {
    this.handleEmbedYoutube()
  },
  methods: {
    handleEmbedYoutube() {
      const videoContainer = document.querySelector('.video-container')
      const lazyLoadingChild = document.querySelector('.lazy-loading-content')

      videoContainer.removeChild(lazyLoadingChild)

      const responsiveDiv = document.createElement('div')

      // classes from bootstrap
      responsiveDiv.classList.add('embed-responsive', 'embed-responsive-16by9')

      const iFrame = document.createElement('iframe')

      iFrame.classList.add('embed-responsive-item')
      // iFrame.setAttribute(
      //   'allow',
      //   'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      // )
      iFrame.src = `${this.embedUrl}?autoplay=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=${this.videoUrl}`
      iFrame.setAttribute('id','ytplayer')
      iFrame.setAttribute('type','text/html')
      iFrame.setAttribute('frameborder', '0')
      iFrame.setAttribute('allowfullscreen', '')
      responsiveDiv.appendChild(iFrame)

      videoContainer.appendChild(responsiveDiv)
    }
  }
}
</script>
