<template>
  <navbar-category :embedUrl="embedUrl"/>
  <div v-if="embedUrl">
    <LoadingEmbedVideo v-if="loading" />
    <embed-video v-else :videoUrl="videoUrl" :embedUrl="embedUrl" />
  </div>
  <LoaderListCampaign v-if="loading" :count="5" />
  <list-category v-else :catId="catId" :catName="catName" />
  <other-category v-if="catId !== null" />
</template>

<script>
import { defineComponent } from 'vue'
import NavbarCategory from '../components/CategoryCampaign/NavbarCategory.vue'
import EmbedVideo from '../components/CategoryCampaign/EmbedVideo.vue'
import ListCategory from '../components/CategoryCampaign/ListCategory.vue'
import OtherCategory from '../components/CategoryCampaign/OtherCategory.vue'

import LoadingEmbedVideo from '../components/Loader/LoaderEmbedVideo.vue'
import LoaderListCampaign from '../components/Loader/LoaderListCampaign.vue'

export default defineComponent({
  components: {
    EmbedVideo,
    NavbarCategory,
    ListCategory,
    OtherCategory,
    LoadingEmbedVideo,
    LoaderListCampaign
  },
  data() {
    return {
      imageThumbnail: null,
      videoUrl: null,
      embedUrl: null,
      catName: null,
      catId: null,
      loading: true
    }
  },
  mounted() {
    this.getDetailCategory()
  },
  methods: {
    getDetailCategory() {
      this.loading = true
      const slugUrl = this.$route.params.slug
      this.$store.dispatch('campaignCategories/fetchCategories').then(() => {
        const campaignCategory = this.$store.getters['campaignCategories/findOne']({
          'key': 'cat_url',
          'value': slugUrl
        })
        if (campaignCategory !== null) {
          this.catName = campaignCategory.cat_name
          this.catId = campaignCategory.cat_id
          this.embedUrl = campaignCategory.cat_video_cover_url
          this.videoUrl = campaignCategory.cat_video_cover_url !== null ? campaignCategory.cat_video_cover_url.replace(
            'https://www.youtube.com/embed/',
            ''
          ) : ''
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }
})
</script>
