<template>
  <div class="video-container">
    <div class="lazy-loading-content image-container">
      <Skeleton width="100" height="274"/>
    </div>
  </div>
</template>

<script>
import Skeleton from '../Skeleton/Skeleton.vue'
export default {
  components: { Skeleton }
}
</script>
