<template>
  <LoaderOtherCategory v-if="loading" :count="3" />
  <div v-else class="other__category-wrapper">
    <h6 class="title__other-category">
      Kategori
    </h6>
    <div class="container category-wrapper row">
      <router-link
        v-for="(category, index) in categories"
        :key="index"
        class="card card__quran col-3"
        :to="`/category/${category.categoryUrl}`"
      >
        <div class="card-body card__body__category">
          <div class="card__icon__quran">
            <img :src="category.categoryImage" alt="Image Category" class="icon__category" />
          </div>
          <div class="card__main">
            <h5 class="card__title">{{ category.categoryName }}</h5>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import LoaderOtherCategory from '../Loader/LoaderOtherCategory.vue'

export default {
  components: { LoaderOtherCategory },
  data() {
    return {
      categories: [],
      loading: true
    }
  },
  mounted() {
    this.getCategory()
  },
  methods: {
    getCategory() {
      this.$store.dispatch('campaignCategories/fetchCategories').then(response => {
        this.categories = []
        response.forEach(value => {
          this.categories.push({
            categoryId: value.cat_id,
            categoryUrl: value.cat_url,
            categoryImage: value.cat_image_cover_url,
            categoryName: value.cat_name,
            categoryShortDesc: value.cat_short_description,
            categoryLongDesc: value.cat_long_description,
            categoryPosition: value.cat_position
          })
        })
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>
