<template>
  <div class="container filter__category-wrapper d-flex align-items-center justify-content-between">
    <div class="title__category">
      <Skeleton width="195px" height="22"/>
    </div>
    <div class="filter__category">
      <Skeleton width="86px" height="26px"/>
    </div>
  </div>
</template>

<script>
import Skeleton from '../Skeleton/Skeleton.vue'
export default {
  components: { Skeleton }
}
</script>
