<template>
  <nav class="navbar navbar-expand-lg navbar-light navbar-category" :style="embedUrl === null ? 'background-color : #F28F1D' : ''">
    <div class="container-fluid container-fluid-category">
      <a class="navbar-brand" @click="$router.back(-1)">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M21.6666 9.66667V12.3333H5.66663L13 19.6667L11.1066 21.56L0.546631 11L11.1066 0.440002L13 2.33334L5.66663 9.66667H21.6666Z" fill="#F2F2F2"/>
        </svg>
      </a>
      <button class="navbar-toggler" type="button" @click="toggleMenu">
        <span class="navbar-toggler-icon navbar-category-icon" />
      </button>
      <div class="nav__menu" id="nav__menu">
        <div class="nav__close d-none" id="nav__close">
          <button type="button" class="btn-close" id="btn-close" aria-label="Close" @click="closeMenu"></button>
        </div>

        <div class="menu__link">
          <ul>
            <li>
              <router-link to="/about" class="link__item">
                <div class="link__icon">
                  <i class="fas fa-info-circle" />
                </div>
                <span>Tentang Kami</span>
              </router-link>
            </li>
            <li>
              <router-link to="/term-condition" class="link__item">
                <div class="link__icon">
                   <i class="fas fa-file-alt" />
                </div>
                <span>Syarat & Ketentuan</span>
              </router-link>
            </li>
            <li>
              <router-link to="/privacy" class="link__item">
                <div class="link__icon">
                  <i class="fas fa-shield-alt" />
                </div>
                <span>Kebijakan Privasi</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: ['embedUrl'],
  methods: {
    toggleMenu() {
      const navMenu = document.getElementById('nav__menu')
      navMenu.classList.toggle('show')
      const navClose = document.getElementById('nav__close')
      navClose.classList.remove('d-none')
    },
    closeMenu() {
      const navMenu = document.getElementById('nav__menu')
      navMenu.classList.remove('show')
      const navClose = document.getElementById('nav__close')
      navClose.classList.toggle('d-none')
    }
  }
})
</script>
