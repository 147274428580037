<template>
  <div class="other__category-wrapper">
    <div class="title__other-category">
      <Skeleton width="100px" height="22" />
    </div>

    <div class="list__other-category">
      <div class="other__category" v-for="n in count" :key="n">
        <div class="">
          <div class="">
            <Skeleton width="62px" height="62" style="border-radius: 6px;" />
          </div>
        </div>
        <Skeleton width="106px" height="14" style="margin-top:0.5rem;" />
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from '../Skeleton/Skeleton.vue'

export default {
  components: { Skeleton },
  props: {
    count: { type: Number, default: 1 }
  }
}
</script>
